import { CrossChainMessenger, DEFAULT_L2_CONTRACT_ADDRESSES } from '@eth-optimism/sdk';
import { TransactionReceipt, TransactionResponse } from '@ethersproject/abstract-provider';
import LoadingButton from '@mui/lab/LoadingButton';
import { BigNumber } from 'ethers';
import React from 'react';
import { useMutation } from 'react-query';
import { useAccount, useSigner } from 'wagmi';

import { BridgeToken, useConfig } from '../../contexts/ConfigContext';
import { addDeposit, saveDepositToStorage } from '../../core/storage/deposit';
import { Side } from '../../core/type';
import { getTimestampIsoString, ZERO_ADDRESS } from '../../core/utils';
import useAlertTransaction from '../../hooks/useAlertTransaction';
import { useApprove } from '../../hooks/useApprove';
import { useReservedParamsNavigate } from '../../hooks/useReservedParamsNavigate';
import { useStaticProvider } from '../../hooks/useStaticProvider';

const DepositERC20Button: React.FC<{ amount: BigNumber; token: BridgeToken; valid?: boolean }> = ({
  amount,
  token,
  valid,
}) => {
  const signer = useSigner();
  const { address } = useAccount();
  const {
    l1: {
      AddressManager,
      L1CrossDomainMessengerProxy,
      L1StandardBridgeProxy,
      L2OutputOracleProxy,
      OptimismPortalProxy,
      id: l1Id,
    },
    l2,
  } = useConfig();
  const l2Provider = useStaticProvider('l2');
  const navigate = useReservedParamsNavigate();
  const { alertConfirmed, alertFailed } = useAlertTransaction(Side.l1);
  const { approve, isApproved } = useApprove(
    token.address,
    address,
    L1StandardBridgeProxy,
    amount,
    'l1'
  );

  const { isLoading: isApproveLoading, mutate: approveMutate } = useMutation<
    TransactionResponse | undefined,
    any,
    void
  >({
    mutationFn: async () => {
      // setConfirmModalShow(true);
      return await approve();
      // setConfirmModalShow(false);
    },
    onSuccess(data) {
      data && alertConfirmed(data.hash);
    },
    onError(e) {
      console.error(e);
      alertFailed(e.message);
    },
  });

  const { isLoading, mutate: depositERC20 } = useMutation<
    | {
        receipt: TransactionReceipt;
        address: string;
        amount: BigNumber;
        l1Address: string;
      }
    | undefined,
    any,
    void
  >({
    mutationFn: async () => {
      if (!signer.data || !signer.isSuccess || !address) {
        return;
      }

      const messenger = new CrossChainMessenger({
        l1ChainId: l1Id,
        l2ChainId: l2.id,
        l1SignerOrProvider: signer.data,
        l2SignerOrProvider: l2Provider,
        contracts: {
          l1: {
            AddressManager,
            L1CrossDomainMessenger: L1CrossDomainMessengerProxy,
            L1StandardBridge: L1StandardBridgeProxy,
            L2OutputOracle: L2OutputOracleProxy,
            OptimismPortal: OptimismPortalProxy,
            StateCommitmentChain: ZERO_ADDRESS,
            CanonicalTransactionChain: ZERO_ADDRESS,
            BondManager: ZERO_ADDRESS,
          },
          l2: DEFAULT_L2_CONTRACT_ADDRESSES,
        },
      });

      const txResponse = await messenger.depositERC20(
        token.address,
        token.oppositeToken.address,
        amount
      );

      saveDepositToStorage({
        transactionHash: txResponse.hash,
        from: address,
        amountOrTokenId: amount.toString(),
        tokenAddress: token.address,
        timestamp: getTimestampIsoString(txResponse.timestamp),
      });
      const receipt = await txResponse.wait();

      return { address, receipt, amount, l1Address: token.address };
    },
    onSuccess(data) {
      if (!data) {
        return;
      }

      const { address, amount, l1Address, receipt } = data;
      const hash = receipt.transactionHash;

      alertConfirmed(hash);

      try {
        addDeposit({
          amountOrTokenId: amount,
          tokenAddress: l1Address,
          account: address,
          transactionHash: hash,
        });
        navigate(`/deposit/progress/${hash}`, { id: true });
      } catch (e) {
        console.error(e);
      }
    },
    onError(e) {
      console.error(e);
      alertFailed(e.message);
    },
  });

  if (!isApproved && valid) {
    return (
      <LoadingButton
        fullWidth
        loading={isApproveLoading}
        onClick={() => approveMutate()}
        variant="contained"
      >
        Approve
      </LoadingButton>
    );
  }

  return (
    <LoadingButton
      disabled={!valid}
      fullWidth
      loading={isLoading}
      onClick={() => depositERC20()}
      variant="contained"
    >
      Deposit
    </LoadingButton>
  );
};

export default DepositERC20Button;
