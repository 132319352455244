import { SvgIcon, SvgIconProps } from '@mui/material';

export default function IconSwitch(props: SvgIconProps) {
  return (
    <SvgIcon height="24px" viewBox="0 0 24 24" width="24px" {...props}>
      <g fill="none" fillRule="evenodd" id="页面-1" stroke="none" strokeWidth="1">
        <g id="op-rollup2" transform="translate(-842, -166)">
          <g id="switch-layer-2" transform="translate(842, 166)">
            <path
              d="M5.25,10.2 L6.3075,11.2575 L4.3725,13.2 L12,13.2 L12,14.7 L4.3725,14.7 L6.3075,16.6425 L5.25,17.7 L1.5,13.95 L5.25,10.2 Z M18.75,5.7 L22.5,9.45 L18.75,13.2 L17.6925,12.1425 L19.6275,10.2 L12,10.2 L12,8.7 L19.6275,8.7 L17.6925,6.7575 L18.75,5.7 Z"
              fill="#9092FE"
              fillRule="nonzero"
              id="形状结合"
            ></path>
            <rect height="24" id="_Transparent_Rectangle_" width="24" x="0" y="0"></rect>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
