import { CrossChainMessenger, DEFAULT_L2_CONTRACT_ADDRESSES } from '@eth-optimism/sdk';
import { TransactionReceipt } from '@ethersproject/abstract-provider';
import LoadingButton from '@mui/lab/LoadingButton';
import { BigNumber } from 'ethers';
import React from 'react';
import { useMutation } from 'react-query';
import { useAccount, useSigner } from 'wagmi';

import { BridgeToken, useConfig } from '../../contexts/ConfigContext';
import { addDeposit } from '../../core/storage/deposit';
import { Side } from '../../core/type';
import useAlertTransaction from '../../hooks/useAlertTransaction';
import { useReservedParamsNavigate } from '../../hooks/useReservedParamsNavigate';
import { useStaticProvider } from '../../hooks/useStaticProvider';

const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

const DepositNativeButton: React.FC<{
  amount: BigNumber;
  token: BridgeToken;
  valid?: boolean;
}> = ({ amount, token, valid }) => {
  const { address } = useAccount();
  const signer = useSigner();
  const { l1, l2 } = useConfig();
  const { alertConfirmed, alertFailed } = useAlertTransaction(Side.l1);
  const navigate = useReservedParamsNavigate();
  const l2Provider = useStaticProvider('l2');
  const { isLoading, mutate: depositNative } = useMutation<
    | {
        receipt: TransactionReceipt;
        address: string;
        amount: BigNumber;
        l1Address: string;
      }
    | undefined,
    any,
    void
  >({
    mutationFn: async () => {
      if (!signer.data || !signer.isSuccess || !address) return;

      const {
        AddressManager,
        L1CrossDomainMessengerProxy,
        L1StandardBridgeProxy,
        L2OutputOracleProxy,
        OptimismPortalProxy,
      } = l1;
      const messenger = new CrossChainMessenger({
        l1ChainId: l1.id,
        l2ChainId: l2.id,
        l1SignerOrProvider: signer.data,
        l2SignerOrProvider: l2Provider,
        contracts: {
          l1: {
            AddressManager,
            L1CrossDomainMessenger: L1CrossDomainMessengerProxy,
            L1StandardBridge: L1StandardBridgeProxy,
            L2OutputOracle: L2OutputOracleProxy,
            OptimismPortal: OptimismPortalProxy,
            StateCommitmentChain: ZERO_ADDRESS,
            CanonicalTransactionChain: ZERO_ADDRESS,
            BondManager: ZERO_ADDRESS,
          },
          l2: DEFAULT_L2_CONTRACT_ADDRESSES,
        },
      });
      const txResponse = await messenger.depositETH(amount, { recipient: address });

      addDeposit({
        amountOrTokenId: amount,
        tokenAddress: token.address,
        account: address,
        transactionHash: txResponse.hash,
      });
      const receipt = await txResponse.wait();

      return { address, receipt, amount, l1Address: token.address };
    },
    onSuccess(data) {
      if (!data) {
        return;
      }

      const { receipt } = data;
      const hash = receipt.transactionHash;

      alertConfirmed(hash);
      navigate(`/deposit/progress/${hash}`, { id: true });
    },
    onError(e) {
      console.error(e);
      alertFailed(e.message);
    },
  });

  return (
    <LoadingButton
      disabled={!valid}
      fullWidth
      loading={isLoading}
      onClick={() => depositNative()}
      variant="contained"
    >
      Deposit
    </LoadingButton>
  );
};

export default DepositNativeButton;
