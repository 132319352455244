export enum Side {
  l1 = 'l1',
  l2 = 'l2',
}

export enum TxType {
  DEPOSIT = 'deposit',
  WITHDRAWAL = 'withdrawal',
}

export interface DepositData {
  message: string;
  blockNumber: number;
  depositHash: string;
  transactionHash: string;
  from: string;
  amountOrTokenId: string;
  tokenAddress: string;
  timestamp: string;
}

export interface WithdrawData {
  blockNumber: number;
  message: string;
  withdrawalHash: string;
  transactionHash: string;
  from: string;
  amountOrTokenId: string;
  tokenAddress: string;
  timestamp: string;
}
