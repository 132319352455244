import { Typography, useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';

import IconSwitch from '../../components/icons/IconSwitch';
import { useConfig } from '../../contexts/ConfigContext';

export default function CurrentChain() {
  const { l2 } = useConfig();
  const theme = useTheme();

  return (
    <Stack
      alignItems="center"
      flexDirection="row"
      justifyContent="space-between"
      sx={{
        height: '48px',
        background: '#28282f',
        borderRadius: '8px',
        mb: 1.25,
      }}
    >
      <Stack alignItems="center" flexDirection="row" px={3}>
        <Typography fontWeight={600} mr={1} variant="body2">
          {l2.name}
        </Typography>
        <Typography color={theme.colors.functional.text.third} variant="body1">
          {l2.id}
        </Typography>
      </Stack>
      <Link to="/chain-selection">
        <Stack alignItems="center" flexDirection="row" px={3}>
          <Typography color={theme.colors.functional.text.link} mr={1} variant="body1">
            Switch Networks
          </Typography>
          <IconSwitch />
        </Stack>
      </Link>
    </Stack>
  );
}
