import {
  Breadcrumbs as MuiBreadcrumbs,
  BreadcrumbsProps,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

import { useReservedParams } from '../hooks/useReservedParams';

const Breadcrumbs: React.FC<
  BreadcrumbsProps & { links: { href?: string; name: string; params?: Record<string, string> }[] }
> = ({ links, ...props }) => {
  const theme = useTheme();
  const queryParams = useReservedParams({ id: true });

  return (
    <MuiBreadcrumbs {...props}>
      {links.map((link, i) =>
        link.href ? (
          <Link
            key={i}
            style={{ color: theme.palette.text.secondary }}
            to={`${link.href}${queryParams}${
              !link.params
                ? ''
                : '&' +
                  Object.entries(link.params)
                    .map(([key, val]) => `${key}=${val}`)
                    .join('&')
            }`}
          >
            {link.name}
          </Link>
        ) : (
          <Typography color="text.primary" key={i}>
            {link.name}
          </Typography>
        )
      )}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
