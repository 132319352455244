import SearchIcon from '@mui/icons-material/Search';
import { Stack } from '@mui/material';
import Input from '@mui/material/Input';

export default function ChainIdInput({
  id,
  onChange,
}: {
  id: string;
  onChange: (id: string) => void;
}) {
  return (
    <Stack flexDirection="row" justifyContent="center" mt={2.5}>
      <Input
        onChange={e => onChange(e.target.value)}
        startAdornment={<SearchIcon sx={{ color: '#7E7E8C', mr: 1 }} />}
        sx={{
          maxWidth: '450px',
          width: '100%',
        }}
        value={id}
      />
    </Stack>
  );
}
