import {
  CrossChainMessenger,
  DEFAULT_L2_CONTRACT_ADDRESSES,
  MessageStatus,
} from '@eth-optimism/sdk';
import { useMemo, useRef, useState } from 'react';

import { useConfig } from '../contexts/ConfigContext';
import { sleep, ZERO_ADDRESS } from '../core/utils';
import { useExecuteOnce } from './useExecuteOnce';
import { useStaticProvider } from './useStaticProvider';

export enum DepositStatus {
  UNKNOWN,
  FAILED_L1_TO_L2_MESSAGE,
  INITIATED,
  RELAYED,
}

export const useDepositStatus = (txHash: string | undefined): DepositStatus => {
  const {
    l1: {
      AddressManager,
      L1CrossDomainMessengerProxy,
      L1StandardBridgeProxy,
      L2OutputOracleProxy,
      OptimismPortalProxy,
      id: l1Id,
    },
    l2,
  } = useConfig();
  const l1Provider = useStaticProvider('l1');
  const l2Provider = useStaticProvider('l2');
  const [status, setStatus] = useState(DepositStatus.UNKNOWN);
  const statusRef = useRef(status);

  const messenger = useMemo(
    () =>
      new CrossChainMessenger({
        l1ChainId: l1Id,
        l2ChainId: l2.id,
        l1SignerOrProvider: l1Provider,
        l2SignerOrProvider: l2Provider,
        contracts: {
          l1: {
            AddressManager,
            L1CrossDomainMessenger: L1CrossDomainMessengerProxy,
            L1StandardBridge: L1StandardBridgeProxy,
            L2OutputOracle: L2OutputOracleProxy,
            OptimismPortal: OptimismPortalProxy,
            StateCommitmentChain: ZERO_ADDRESS,
            CanonicalTransactionChain: ZERO_ADDRESS,
            BondManager: ZERO_ADDRESS,
          },
          l2: DEFAULT_L2_CONTRACT_ADDRESSES,
        },
      }),
    [
      AddressManager,
      L1CrossDomainMessengerProxy,
      L1StandardBridgeProxy,
      L2OutputOracleProxy,
      OptimismPortalProxy,
      l1Id,
      l1Provider,
      l2.id,
      l2Provider,
    ]
  );

  const argsRef = useExecuteOnce<{ txHash: string | undefined; messenger: CrossChainMessenger }>(
    async (destroiedRef, argsRef) => {
      while (!destroiedRef.current && statusRef.current !== DepositStatus.RELAYED) {
        if (!argsRef.current || !argsRef.current.txHash) {
          await sleep(1000);
          continue;
        }

        try {
          const status = await argsRef.current.messenger.getMessageStatus(argsRef.current?.txHash);
          let depositStatus = DepositStatus.UNKNOWN;

          if (status === MessageStatus.FAILED_L1_TO_L2_MESSAGE) {
            depositStatus = DepositStatus.FAILED_L1_TO_L2_MESSAGE;
          } else if (status === MessageStatus.RELAYED) {
            depositStatus = DepositStatus.RELAYED;
          } else if (status === MessageStatus.UNCONFIRMED_L1_TO_L2_MESSAGE) {
            depositStatus = DepositStatus.INITIATED;
          } else {
            depositStatus = DepositStatus.UNKNOWN;
          }

          statusRef.current = depositStatus;
          setStatus(depositStatus);
        } catch (e) {
          console.log(e);
          statusRef.current = DepositStatus.UNKNOWN;
          setStatus(DepositStatus.UNKNOWN);
        }

        await sleep(window.appConfig.rpcPollingInterval || 60000);
      }
    }
  );

  argsRef.current = { txHash, messenger };

  return status;
};
