import { connectorsForWallets, darkTheme, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import {
  coinbaseWallet,
  metaMaskWallet,
  rainbowWallet,
  walletConnectWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { createContext, useContext, useMemo } from 'react';
import { Chain, configureChains, createClient, WagmiConfig } from 'wagmi';
import { goerli } from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';

import { useConfig } from './ConfigContext';

interface Context {
  chainsConfig: Chain[];
  chains: Chain[];
}

export const WagmiContext = createContext<Context>({} as Context);

export const useWagmi = () => {
  return useContext(WagmiContext);
};

const appName = 'AltLayer Rollup Bridge';

const theme = darkTheme();

theme.colors.accentColor = '#6667ab';
theme.colors.profileForeground = '#373741';
theme.colors.modalBackground = '#373741';
theme.radii.modal = '16px';

const projectId = window.appConfig?.walletConnectProjectId || '';

export const WagmiProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { l1, l2 } = useConfig();
  const chainsConfig = useMemo(() => (l1 && l2 ? ([l1, l2] as Chain[]) : [goerli]), [l1, l2]);

  const { chains: configuredChains, provider } = useMemo(
    () => configureChains(chainsConfig, [publicProvider()]),
    [chainsConfig]
  );

  const connectors = useMemo(
    () =>
      connectorsForWallets([
        {
          groupName: 'Recommended',
          wallets: [
            metaMaskWallet({ chains: configuredChains, projectId }),
            rainbowWallet({ chains: configuredChains, projectId }),
            walletConnectWallet({
              chains: configuredChains,
              options: {
                bridge: 'wss://relay.walletconnect.com',
                projectId,
              },
              projectId,
            }),
            coinbaseWallet({ chains: configuredChains, appName }),
          ],
        },
      ]),
    [configuredChains]
  );

  const wagmiClient = useMemo(
    // Reinitialize the wagmi client if new configs are set (Usually when new networks are added via setChainsConfig)
    () =>
      createClient({
        autoConnect: true,
        connectors,
        provider,
      }),
    [provider, connectors]
  );

  return (
    <WagmiContext.Provider value={{ chains: configuredChains, chainsConfig }}>
      <WagmiConfig client={wagmiClient}>
        <RainbowKitProvider chains={configuredChains} theme={theme}>
          {children}
        </RainbowKitProvider>
      </WagmiConfig>
    </WagmiContext.Provider>
  );
};
