import { SvgIcon, SvgIconProps } from '@mui/material';

export default function IconAltLayer({ ...props }: SvgIconProps) {
  return (
    <SvgIcon height="30px" viewBox="0 0 30 30" width="30px" {...props}>
      <g fill="none" fillRule="evenodd" id="页面-1" stroke="none" strokeWidth="1">
        <g id="Polygon备份">
          <rect fill="#F9F9F9" height="30" id="矩形备份-9" rx="15" width="30" x="0" y="0"></rect>
          <circle cx="15" cy="15" fill="#F9F9F9" id="椭圆形" r="15"></circle>
          <g id="编组-4" transform="translate(3.000000, 2.700000)">
            <rect height="25" id="矩形" width="25" x="0" y="0"></rect>
            <g
              fill="#6667AB"
              fillRule="nonzero"
              id="AltLayer---Original---Complete---Blue"
              transform="translate(1.041667, 1.666667)"
            >
              <path
                d="M12.1299243,21.5908648 C12.2905323,21.5910968 12.4496239,21.559605 12.5981017,21.4981378 C12.7464629,21.4366706 12.8812942,21.3465108 12.9947812,21.2329072 L22.5583605,11.6693279 C22.6719641,11.555736 22.7620072,11.4209047 22.8234744,11.2725202 C22.8849416,11.1241357 22.9166667,10.9650907 22.9166667,10.8044711 C22.9166667,10.6438514 22.8849416,10.4848064 22.8234744,10.3364103 C22.7620072,10.1880258 22.6719641,10.0532061 22.5583605,9.93964916 L12.9947812,0.37608153 C12.8812942,0.2623823 12.7464629,0.172226073 12.5981017,0.110793474 C12.4496239,0.0493612257 12.2905323,0.0178639893 12.1299243,0.0181105049 L12.1299243,21.5908648 Z"
                id="路径"
              ></path>
              <path
                d="M10.7870926,17.5663339 C10.4741928,17.5663339 10.1612931,17.4925033 9.9226439,17.3418094 L0.357969398,11.3464558 C-0.119323133,11.0472142 -0.119323133,10.5617979 0.357969398,10.2625679 L9.9219091,4.26723771 C10.1605583,4.11761688 10.473458,4.04263151 10.7863578,4.04263151 L10.7870926,17.5663339 Z"
                id="路径"
                opacity="0.5"
              ></path>
              <path
                d="M10.7870926,13.5233758 C10.4741928,13.5233758 10.1612931,13.4495452 9.9226439,13.2992012 L0.357969398,7.3038243 C-0.119323133,7.00459431 -0.119323133,6.51916641 0.357969398,6.21957486 L9.9219091,0.224608524 C10.159077,0.0738842048 10.473458,0 10.7870926,0 L10.7870926,13.5233758 Z"
                id="路径"
                opacity="0.5"
              ></path>
              <path
                d="M10.7870926,21.6088255 C10.4741928,21.6088255 10.1612931,21.5349948 9.9226439,21.3843009 L0.357969398,15.3893206 C-0.119323133,15.0896824 -0.119323133,14.6042428 0.357969398,14.3050712 L9.9219091,8.30971759 C10.1605583,8.16010843 10.473458,8.08548464 10.7863578,8.08548464 L10.7870926,21.6088255 Z"
                id="路径"
                opacity="0.5"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
