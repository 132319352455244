import { Box, CssBaseline, Stack } from '@mui/material';
import { createContext, useContext, useEffect, useState } from 'react';

import IconLogo from '../components/icons/IconLogo';
import { BaseLayout } from '../components/Layout';
import Footer from '../components/Layout/Footer';
import { Side } from '../core/type';
import { L1, L2 } from '../global';
import { useCustomSearchParams } from '../hooks/useCustomSearchParams';
import ChainSelection from '../pages/ChainSelection';
import { inputGlobalStyles } from '../theme/globalStyle';

export enum TokenType {
  ERC20 = 'erc20',
  ERC721 = 'erc721',
  NATIVE = 'native',
}

export class BridgeToken {
  side!: Side;
  address!: string;
  name!: string;
  symbol!: string;
  decimals!: number;
  type!: TokenType;
  oppositeToken!: BridgeToken;
}

interface Context {
  l1: L1;
  l2: L2;
  l1Tokens: BridgeToken[];
  l2Tokens: BridgeToken[];
}

export const ConfigContext = createContext<Context>({} as Context);

export const useConfig = () => {
  return useContext(ConfigContext);
};

const appConfig = window.appConfig;

const PlaceholderForConfig: React.FC = () => (
  <BaseLayout>
    <Stack alignItems="center" flexDirection="row" py={3.75}>
      <IconLogo />
    </Stack>
    <CssBaseline />
    {inputGlobalStyles}
    <Box
      sx={{
        minHeight: 'calc(100vh - 204px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <ChainSelection />
    </Box>
    <Footer />
  </BaseLayout>
);

export const ConfigProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [{ l1, l2 }, setNetwork] = useState<{ l1: L1 | null; l2: L2 | null }>({
    l1: null,
    l2: null,
  });
  const [{ l1Tokens, l2Tokens }, setTokens] = useState<{
    l1Tokens: BridgeToken[];
    l2Tokens: BridgeToken[];
  }>({
    l1Tokens: [],
    l2Tokens: [],
  });
  const {
    searchParms: { id },
  } = useCustomSearchParams<{ id?: string }>();

  useEffect(() => {
    if (!id) {
      return;
    }

    const bridgeConfig = appConfig.bridgeMap[id];

    if (!bridgeConfig) {
      return;
    }

    const l1NativeMeta = bridgeConfig.l1.nativeCurrency;
    const l2NativeMeta = bridgeConfig.l2.nativeCurrency;
    const l1NativeToken: BridgeToken = Object.assign(new BridgeToken(), {
      address: appConfig?.nativeTokenAddress,
      name: l1NativeMeta.name,
      symbol: l1NativeMeta.symbol,
      decimals: l1NativeMeta.decimals,
      type: TokenType.NATIVE,
      side: Side.l1,
    });
    const l2NativeToken: BridgeToken = Object.assign(new BridgeToken(), {
      address: appConfig?.nativeTokenAddress,
      name: l2NativeMeta.name,
      symbol: l2NativeMeta.symbol,
      decimals: l2NativeMeta.decimals,
      type: TokenType.NATIVE,
      side: Side.l2,
    });
    const l1Tokens = bridgeConfig.tokens.map(token =>
      Object.assign(new BridgeToken(), token, {
        side: Side.l1,
        address: token.l1Address,
      })
    );
    const l2Tokens = bridgeConfig.tokens.map(token =>
      Object.assign(new BridgeToken(), token, {
        side: Side.l2,
        address: token.l2Address,
      })
    );

    l1NativeToken.oppositeToken = l2NativeToken;
    l2NativeToken.oppositeToken = l1NativeToken;
    l1Tokens.forEach((token, i) => {
      token.oppositeToken = l2Tokens[i];
      l2Tokens[i].oppositeToken = token;
    });

    setNetwork({
      l1: bridgeConfig.l1,
      l2: bridgeConfig.l2,
    });
    setTokens({
      l1Tokens: [l1NativeToken, ...l1Tokens],
      l2Tokens: [l2NativeToken, ...l2Tokens],
    });
  }, [id]);

  if (!l1 || !l2) {
    return <PlaceholderForConfig />;
  }

  return (
    <ConfigContext.Provider
      value={{
        l1,
        l2,
        l1Tokens,
        l2Tokens,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};
