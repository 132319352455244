import { BigNumberish } from 'ethers';

import { WITHDRAW_KEY } from './withdraw';

export const DEPOSIT_KEY = 'deposit-list';

export interface Deposit {
  transactionHash: string;
  from: string;
  amountOrTokenId: string;
  // for indexing token
  tokenAddress: string;
  timestamp: string;
}

interface IAddDeposit {
  transactionHash: string;
  amountOrTokenId: BigNumberish;
  tokenAddress: string;
  account: string;
}

export function saveDepositToStorage(deposit: Deposit) {
  const data = localStorage.getItem(getStorageKey('deposit'));
  let depositList: Deposit[] = [];

  try {
    depositList = data ? JSON.parse(data) : [];
  } catch (e) {
    console.error(e);
  }

  const existingIndex = depositList.findIndex(
    _deposit =>
      _deposit.transactionHash &&
      deposit.transactionHash &&
      _deposit.transactionHash === deposit.transactionHash
  );

  if (existingIndex < 0) {
    depositList.push(deposit);
  } else {
    depositList.splice(existingIndex, 1, deposit);
  }

  localStorage.setItem(getStorageKey('deposit'), JSON.stringify(depositList));
}

export function addDeposit({
  account,
  amountOrTokenId,
  tokenAddress,
  transactionHash,
}: IAddDeposit): void {
  const deposit: Deposit = {
    transactionHash,
    from: account,
    amountOrTokenId: amountOrTokenId.toString(),
    tokenAddress,
    timestamp: new Date().toISOString(),
  };

  saveDepositToStorage(deposit);
}

export function getDepositByTransactionHash(transactionHash: string): Deposit | undefined {
  const data = localStorage.getItem(getStorageKey('deposit'));
  let depositList: Deposit[] = [];

  try {
    depositList = data ? JSON.parse(data) : [];
  } catch (e) {
    console.debug(e);
  }

  return depositList.find(deposit => deposit.transactionHash === transactionHash);
}

export function getStorageKey(type: 'deposit' | 'withdrawal') {
  const params = new URLSearchParams(window.location.search);
  const rollupId = params.get('id');

  return `${type === 'deposit' ? DEPOSIT_KEY : WITHDRAW_KEY}${rollupId ?? ''}`;
}
