import { SvgIcon, SvgIconProps } from '@mui/material';

export default function IconNext(props: SvgIconProps) {
  return (
    <SvgIcon height="12px" viewBox="0 0 12 12" width="12px" {...props}>
      <g fill="none" fillRule="evenodd" id="页面-1" stroke="none" strokeWidth="1">
        <g id="op-rollup" transform="translate(-428, -408)">
          <g id="编组-3" transform="translate(120, 272)">
            <g id="编组-2" transform="translate(308, 136)">
              <rect height="12" id="矩形" width="12" x="0" y="0"></rect>
              <path
                d="M7.35355339,2.14644661 L11.7071068,6.5 L7.35355339,10.8535534 L6.64644661,10.1464466 L9.792,6.99944661 L0,7 L0,6 L9.792,5.99944661 L6.64644661,2.85355339 L7.35355339,2.14644661 Z"
                fill="#9092FE"
                fillRule="nonzero"
                id="形状结合"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
