import { MutableRefObject, useEffect, useRef } from 'react';

export type OnceCallback<T> = (
  destroiedRef: MutableRefObject<boolean>,
  args: MutableRefObject<T | null>
) => Promise<void>;

export function useExecuteOnce<T>(callback: OnceCallback<T>) {
  const executedRef = useRef(false);
  const destroiedRef = useRef(false);
  const callbackRef = useRef<null | OnceCallback<T>>(null);
  const argsRef = useRef<T | null>(null);

  callbackRef.current = callback;

  useEffect(() => {
    destroiedRef.current = false;

    async function run() {
      if (executedRef.current) {
        return;
      }

      executedRef.current = true;

      if (callbackRef.current) {
        await callbackRef.current(destroiedRef, argsRef);
      }
    }

    run().catch(console.error);

    return () => {
      destroiedRef.current = true;
    };
  }, []);

  return argsRef;
}
