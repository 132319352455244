import { Grid, Pagination, Stack, Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import IconNext from '../../components/icons/IconNext';
import ChainIdInput from './ChainIdInput';

export default function ChainSelection() {
  const [id, onChange] = useState('');
  const theme = useTheme();
  const [page, setPage] = useState(1);
  const chainPairs = useMemo(
    () =>
      Object.values(window.appConfig.bridgeMap)
        .map(({ description, l1, l2 }) => ({
          l1,
          l2,
          description,
        }))
        .filter(({ l2 }) => l2.id.toString().includes(id)),
    [id]
  );

  return (
    <Box width="100%">
      <Typography textAlign="center" variant="h2">
        Select a Network
      </Typography>
      <ChainIdInput id={id} onChange={onChange} />
      <Box mt={5} />
      <Grid container spacing={1.25} width="100%">
        {chainPairs.slice((page - 1) * 9, page * 9).map(pair => (
          <Grid item key={pair.l2.id} lg={4} md={6} xs={12}>
            <Box
              sx={{
                p: 2.5,
                background: theme.colors.functional.container.default,
                border: '1px solid rgba(255,255,255,0.04)',
                borderRadius: '8px',
                boxShadow: '0px 0px 40px 0px rgba(26,26,37,0.20);',
              }}
            >
              <Stack flexDirection="row" justifyContent="space-between">
                <Typography
                  color={theme.colors.functional.text.lint}
                  fontSize="12px"
                  lineHeight="16px"
                  whiteSpace="nowrap"
                >
                  Settlement chain: {pair.l1.name}
                </Typography>
                <Typography
                  color={theme.colors.functional.text.lint}
                  fontSize="12px"
                  lineHeight="16px"
                  whiteSpace="nowrap"
                >
                  Chain ID
                </Typography>
              </Stack>

              <Stack flexDirection="row" justifyContent="space-between" mt={0.5}>
                <Typography fontSize="12px" variant="body1" whiteSpace="nowrap">
                  {pair.l2.name}
                </Typography>
                <Typography lineHeight="16px" variant="body1" whiteSpace="nowrap">
                  {pair.l2.id}
                </Typography>
              </Stack>
              <Typography mb={2.5} mt={2.5} variant="body1" whiteSpace="nowrap">
                {pair.description}
              </Typography>

              <Stack
                alignItems="center"
                flexDirection="row"
                justifyContent="flex-end"
                sx={{
                  cursor: 'pointer',
                  whiteSpace: 'nowrap',
                }}
              >
                <Link to={`/deposit?id=${pair.l2.id}`}>
                  <Typography
                    color={theme.colors.functional.text.link}
                    fontWeight={500}
                    mr={1}
                    variant="body1"
                  >
                    Select
                  </Typography>
                </Link>
                <Link to={`/deposit?id=${pair.l2.id}`}>
                  <IconNext sx={{ width: '12px', height: 'auto' }} />
                </Link>
              </Stack>
            </Box>
          </Grid>
        ))}
      </Grid>
      {chainPairs.length > 9 && (
        <Stack flexDirection="row" justifyContent="flex-end">
          <Pagination
            count={Math.ceil(chainPairs.length / 9)}
            onChange={(e, page) => setPage(page)}
            page={page}
            sx={{
              mt: 5,
              justifyContent: 'flex-end',
            }}
          />
        </Stack>
      )}
    </Box>
  );
}
