import { CrossChainMessenger, DEFAULT_L2_CONTRACT_ADDRESSES } from '@eth-optimism/sdk';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { useMutation } from 'react-query';
import { useSigner } from 'wagmi';

import EnsureNetworkButton from '../../components/EnsureNetworkButton';
import { useConfig } from '../../contexts/ConfigContext';
import { Side } from '../../core/type';
import { ZERO_ADDRESS } from '../../core/utils';
import useAlertTransaction from '../../hooks/useAlertTransaction';
import { useStaticProvider } from '../../hooks/useStaticProvider';

const FinalizeMessage: React.FC<{ transactionHash?: string } & LoadingButtonProps> = ({
  transactionHash,
  ...props
}) => {
  const { l1, l2 } = useConfig();
  const { data: signer, isSuccess } = useSigner();
  const { alertConfirmed, alertFailed } = useAlertTransaction(Side.l1);
  const l2Provider = useStaticProvider('l2');

  const { isLoading, mutate: proveMessage } = useMutation<string | undefined, any, any>({
    mutationFn: async (transactionHash: string) => {
      if (!signer || !isSuccess) return;

      const {
        AddressManager,
        L1CrossDomainMessengerProxy,
        L1StandardBridgeProxy,
        L2OutputOracleProxy,
        OptimismPortalProxy,
      } = l1;
      const messenger = new CrossChainMessenger({
        l1ChainId: l1.id,
        l2ChainId: l2.id,
        l1SignerOrProvider: signer,
        l2SignerOrProvider: l2Provider,
        contracts: {
          l1: {
            AddressManager,
            L1CrossDomainMessenger: L1CrossDomainMessengerProxy,
            L1StandardBridge: L1StandardBridgeProxy,
            L2OutputOracle: L2OutputOracleProxy,
            OptimismPortal: OptimismPortalProxy,
            StateCommitmentChain: ZERO_ADDRESS,
            CanonicalTransactionChain: ZERO_ADDRESS,
            BondManager: ZERO_ADDRESS,
          },
          l2: DEFAULT_L2_CONTRACT_ADDRESSES,
        },
      });
      const txResponse = await messenger.finalizeMessage(transactionHash);
      const receipt = await txResponse.wait();

      return receipt.transactionHash;
    },
    onSuccess(hash) {
      if (!hash) {
        return;
      }

      alertConfirmed(hash);
    },
    onError(e) {
      console.error(e);
      alertFailed(e.message);
    },
  });

  return (
    <EnsureNetworkButton targetChainId={l1.id}>
      <LoadingButton
        loading={isLoading}
        onClick={() => proveMessage(transactionHash)}
        variant="contained"
        {...props}
      >
        Finalize Message
      </LoadingButton>
    </EnsureNetworkButton>
  );
};

export default FinalizeMessage;
