import { Box, Container, Stack, Typography, useTheme } from '@mui/material';
import { BigNumber } from 'ethers';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import Breadcrumbs from '../../components/Breadcrumbs';
import IconAwaitingChallengePeriod from '../../components/icons/IconAwaitingChallengePeriod';
import IconCircleChecked from '../../components/icons/IconCircleChecked';
import IconLoadCircle from '../../components/icons/IconLoadCircle';
import IconNotFound from '../../components/icons/IconNotFound';
import IconWithdrawable from '../../components/icons/IconWithdrawable';
import IconWithdrawInitiated from '../../components/icons/IconWithdrawInitiated';
import ProgressStepper from '../../components/ProgressStepper';
import TransferAmount from '../../components/TransferAmount';
import TransferDirection from '../../components/TransferDirection';
import { useConfig } from '../../contexts/ConfigContext';
import { Side } from '../../core/type';
import { useWithdrawalStatus, WithdrawalStatus } from '../../hooks/useWithdrawalStatus';
import { useWithdrawInLS } from '../../hooks/useWithdrawInLS';
import { TxnHistoryTab } from '../TransactionHistory';
import FinalizeMessage from './FinalizeMessage';
import ProveMessage from './ProveMessage';

const steps = [
  WithdrawalStatus.STATE_ROOT_NOT_PUBLISHED,
  WithdrawalStatus.READY_TO_PROVE,
  WithdrawalStatus.IN_CHALLENGE_PERIOD,
  WithdrawalStatus.READY_FOR_RELAY,
  WithdrawalStatus.RELAYED,
];

const WithdrawalProgress: React.FC = () => {
  const { transactionHash } = useParams<{ transactionHash: string }>();
  const status = useWithdrawalStatus(transactionHash);
  const withdrawal = useWithdrawInLS(transactionHash);
  const theme = useTheme();
  const { l2Tokens } = useConfig();
  const token = useMemo(
    () => l2Tokens.find(token => token.address === withdrawal?.tokenAddress),
    [l2Tokens, withdrawal?.tokenAddress]
  );
  const activeStep = steps.findIndex(cur => cur === status);

  const content = useMemo(() => {
    if (!withdrawal) {
      return (
        <>
          <IconNotFound
            sx={{
              width: '48px',
              height: 'auto',
            }}
          />
          <Typography mt={2.5} variant="body2">
            Sorry, no transaction details were found by giving transaction hash
          </Typography>
        </>
      );
    }

    if (status === WithdrawalStatus.UNKNOWN) {
      return (
        <IconLoadCircle
          sx={{
            animation: 'rotation 1.5s infinite linear',
            width: '48px',
            height: 'auto',
          }}
        />
      );
    } else if (status === WithdrawalStatus.STATE_ROOT_NOT_PUBLISHED) {
      return (
        <>
          <IconWithdrawInitiated sx={{ width: '48px', height: 'auto' }} />
          <Typography mt={2.5} variant="h6">
            Waiting for state root to be published
          </Typography>
        </>
      );
    } else if (status === WithdrawalStatus.READY_TO_PROVE) {
      return (
        <>
          <Typography mb={2.5} variant="body2">
            Ready for prove
          </Typography>
          <ProveMessage transactionHash={transactionHash} />
        </>
      );
    } else if (status === WithdrawalStatus.IN_CHALLENGE_PERIOD) {
      return (
        <>
          <IconAwaitingChallengePeriod sx={{ width: '48px', height: 'auto' }} />
          <Typography mt={2.5} variant="h6">
            Waiting for challenge period
          </Typography>
        </>
      );
    } else if (status === WithdrawalStatus.READY_FOR_RELAY) {
      return (
        <>
          <IconWithdrawable sx={{ width: '48px', height: 'auto' }} />
          <FinalizeMessage sx={{ mt: 2.5 }} transactionHash={transactionHash} />
        </>
      );
    } else if (status === WithdrawalStatus.RELAYED) {
      return (
        <>
          <IconCircleChecked sx={{ width: '48px', height: 'auto' }} />
          <Typography mt={2.5} variant="h6">
            Completed
          </Typography>
        </>
      );
    }
  }, [status, transactionHash, withdrawal]);

  return (
    <Box>
      <Breadcrumbs
        links={[
          { href: '/', name: 'home' },
          {
            href: '/transactions',
            name: 'transactions',
            params: {
              type: TxnHistoryTab.Withdrawal,
            },
          },
          { name: 'withdrawal progress' },
        ]}
      />
      <Container
        sx={{
          background: theme.colors.functional.container.default,
          borderRadius: '4px',
          py: 3,
          my: 1,
        }}
      >
        <Typography variant="h5">Withdrawal progress</Typography>
        <TransferDirection from={Side.l2} />
        {token && (
          <TransferAmount
            amount={BigNumber.from(withdrawal?.amountOrTokenId)}
            from={Side.l2}
            mb={5}
            token={token}
          />
        )}
        {status !== WithdrawalStatus.UNKNOWN && (
          <ProgressStepper activeStep={activeStep} steps={steps} />
        )}
        <Stack alignItems="center" justifyContent="center" minHeight="10rem" mt={6.5}>
          {content}
        </Stack>
      </Container>
    </Box>
  );
};

export default WithdrawalProgress;
