import { SvgIcon, SvgIconProps } from '@mui/material';

export default function IconERC20Token(props: SvgIconProps) {
  return (
    <SvgIcon height="28px" viewBox="0 0 28 28" width="28px" {...props}>
      <g fill="none" fillRule="evenodd" id="页面-1" stroke="none" strokeWidth="1">
        <g id="rollp-bridge-ERC20" transform="translate(-737.000000, -304.000000)">
          <g id="编组-5" transform="translate(414.000000, 288.000000)">
            <g id="erc20" transform="translate(323.000000, 16.000000)">
              <circle
                cx="14"
                cy="14"
                fill="#5B7AF6"
                id="椭圆形备份"
                r="13.5"
                stroke="#506BD8"
              ></circle>
              <g
                fill="#FFFFFF"
                fillRule="nonzero"
                id="ethereum-eth-logo-full-horizontal备份-3"
                transform="translate(6.000000, 1.000000)"
              >
                <path
                  d="M15.9593496,14.7583149 L7.97487066,26 L0,14.7583149 L7.97487066,19.466371 L15.9593496,14.7583149 Z M2.115,16.7033149 L7.679,24.545 L7.679,19.989 L2.115,16.7033149 Z M13.843,16.7013149 L8.279,19.983 L8.279,24.534 L13.843,16.7013149 Z M7.97487066,0 L8.276,0.501 L8.3,0.501084715 L8.3,0.541 L15.9593496,13.2402069 L7.97487066,17.9578714 L0,13.2402069 L7.97487066,0 Z M7.7,10.401 L1.297,13.3098862 L7.7,17.096 L7.7,10.401 Z M8.3,10.423 L8.3,17.067 L14.661,13.3098862 L8.3,10.423 Z M7.7,1.617 L0.963,12.803 L7.7,9.742 L7.7,1.617 Z M8.3,1.699 L8.3,9.765 L14.994,12.802 L8.3,1.699 Z"
                  id="形状结合"
                ></path>
              </g>
              <g fill="#5B7AF6" id="20" transform="translate(5.746000, 9.770000)">
                <path
                  d="M6.617,9.23 L0.195,9.23 L0.195,7.449 L2.99,5.057 C3.24133333,4.84033333 3.45366667,4.64316667 3.627,4.4655 C3.80033333,4.28783333 3.94116667,4.1145 4.0495,3.9455 C4.15783333,3.7765 4.23583333,3.60966667 4.2835,3.445 C4.33116667,3.28033333 4.355,3.10266667 4.355,2.912 L4.355,2.73 C4.355,2.55666667 4.32033333,2.405 4.251,2.275 C4.18166667,2.145 4.09066667,2.03666667 3.978,1.95 C3.86533333,1.86333333 3.7375,1.79833333 3.5945,1.755 C3.4515,1.71166667 3.30633333,1.69 3.159,1.69 C2.94233333,1.69 2.75383333,1.72466667 2.5935,1.794 C2.43316667,1.86333333 2.2945,1.9565 2.1775,2.0735 C2.0605,2.1905 1.963,2.32483333 1.885,2.4765 C1.807,2.62816667 1.74633333,2.79066667 1.703,2.964 L0,2.314 C0.104,2.002 0.247,1.70516667 0.429,1.4235 C0.611,1.14183333 0.8385,0.894833333 1.1115,0.6825 C1.3845,0.470166667 1.70733333,0.303333333 2.08,0.182 C2.45266667,0.0606666667 2.873,0 3.341,0 C3.835,0 4.27266667,0.0693333333 4.654,0.208 C5.03533333,0.346666667 5.35816667,0.537333333 5.6225,0.78 C5.88683333,1.02266667 6.08833333,1.31083333 6.227,1.6445 C6.36566667,1.97816667 6.435,2.34 6.435,2.73 C6.435,3.12 6.37,3.47533333 6.24,3.796 C6.11,4.11666667 5.9345,4.41783333 5.7135,4.6995 C5.4925,4.98116667 5.23466667,5.24766667 4.94,5.499 C4.64533333,5.75033333 4.33766667,6.006 4.017,6.266 L2.327,7.592 L6.617,7.592 L6.617,9.23 Z"
                  fillRule="nonzero"
                  id="路径"
                  stroke="#5B7AF6"
                  strokeWidth="1.44"
                ></path>
                <path
                  d="M13.154,9.386 C11.9666667,9.386 11.087,8.97433333 10.515,8.151 C9.943,7.32766667 9.657,6.175 9.657,4.693 C9.657,3.211 9.943,2.05833333 10.515,1.235 C11.087,0.411666667 11.9666667,0 13.154,0 C14.3413333,0 15.221,0.411666667 15.793,1.235 C16.365,2.05833333 16.651,3.211 16.651,4.693 C16.651,6.175 16.365,7.32766667 15.793,8.151 C15.221,8.97433333 14.3413333,9.386 13.154,9.386 Z M13.154,7.735 C13.674,7.735 14.0336667,7.54433333 14.233,7.163 C14.4323333,6.78166667 14.532,6.24433333 14.532,5.551 L14.532,3.835 C14.532,3.14166667 14.4323333,2.60433333 14.233,2.223 C14.0336667,1.84166667 13.674,1.651 13.154,1.651 C12.634,1.651 12.2743333,1.84166667 12.075,2.223 C11.8756667,2.60433333 11.776,3.14166667 11.776,3.835 L11.776,5.551 C11.776,6.24433333 11.8756667,6.78166667 12.075,7.163 C12.2743333,7.54433333 12.634,7.735 13.154,7.735 Z"
                  fillRule="nonzero"
                  id="形状"
                  stroke="#5B7AF6"
                  strokeWidth="1.44"
                ></path>
                <rect height="5" id="矩形" width="2" x="12" y="2"></rect>
                <rect height="2" id="矩形" width="1" x="0.254" y="5.23"></rect>
              </g>
              <g
                fill="#FFFFFF"
                fillRule="nonzero"
                id="20"
                transform="translate(5.941000, 9.770000)"
              >
                <path
                  d="M6.292,9.23 L0.13,9.23 L0.13,7.826 L3.029,5.239 C3.48833333,4.823 3.8415,4.4265 4.0885,4.0495 C4.3355,3.6725 4.459,3.276 4.459,2.86 L4.459,2.691 C4.459,2.249 4.32683333,1.90233333 4.0625,1.651 C3.79816667,1.39966667 3.44066667,1.274 2.99,1.274 C2.73866667,1.274 2.51983333,1.31083333 2.3335,1.3845 C2.14716667,1.45816667 1.98466667,1.56 1.846,1.69 C1.70733333,1.82 1.5925,1.97166667 1.5015,2.145 C1.4105,2.31833333 1.339,2.50466667 1.287,2.704 L0,2.21 C0.0953333333,1.924 0.225333333,1.64883333 0.39,1.3845 C0.554666667,1.12016667 0.762666667,0.884 1.014,0.676 C1.26533333,0.468 1.56216667,0.303333333 1.9045,0.182 C2.24683333,0.0606666667 2.64766667,0 3.107,0 C3.56633333,0 3.978,0.0671666667 4.342,0.2015 C4.706,0.335833333 5.0115,0.522166667 5.2585,0.7605 C5.5055,0.998833333 5.694,1.28266667 5.824,1.612 C5.954,1.94133333 6.019,2.301 6.019,2.691 C6.019,3.055 5.96266667,3.393 5.85,3.705 C5.73733333,4.017 5.58133333,4.31166667 5.382,4.589 C5.18266667,4.86633333 4.95083333,5.13066667 4.6865,5.382 C4.42216667,5.63333333 4.14266667,5.889 3.848,6.149 L1.716,7.982 L6.292,7.982 L6.292,9.23 Z"
                  id="路径"
                ></path>
                <path
                  d="M12.959,9.386 C12.3956667,9.386 11.906,9.27983333 11.49,9.0675 C11.074,8.85516667 10.7316667,8.54966667 10.463,8.151 C10.1943333,7.75233333 9.995,7.26266667 9.865,6.682 C9.735,6.10133333 9.67,5.43833333 9.67,4.693 C9.67,3.95633333 9.735,3.2955 9.865,2.7105 C9.995,2.1255 10.1943333,1.63366667 10.463,1.235 C10.7316667,0.836333333 11.074,0.530833333 11.49,0.3185 C11.906,0.106166667 12.3956667,0 12.959,0 C14.0856667,0 14.9155,0.411666667 15.4485,1.235 C15.9815,2.05833333 16.248,3.211 16.248,4.693 C16.248,6.175 15.9815,7.32766667 15.4485,8.151 C14.9155,8.97433333 14.0856667,9.386 12.959,9.386 Z M12.959,8.138 C13.271,8.138 13.5375,8.07516667 13.7585,7.9495 C13.9795,7.82383333 14.1593333,7.644 14.298,7.41 C14.4366667,7.176 14.5363333,6.8965 14.597,6.5715 C14.6576667,6.2465 14.688,5.88466667 14.688,5.486 L14.688,3.9 C14.688,3.50133333 14.6576667,3.1395 14.597,2.8145 C14.5363333,2.4895 14.4366667,2.21 14.298,1.976 C14.1593333,1.742 13.9795,1.56216667 13.7585,1.4365 C13.5375,1.31083333 13.271,1.248 12.959,1.248 C12.647,1.248 12.3805,1.31083333 12.1595,1.4365 C11.9385,1.56216667 11.7586667,1.742 11.62,1.976 C11.4813333,2.21 11.3816667,2.4895 11.321,2.8145 C11.2603333,3.1395 11.23,3.50133333 11.23,3.9 L11.23,5.486 C11.23,5.88466667 11.2603333,6.2465 11.321,6.5715 C11.3816667,6.8965 11.4813333,7.176 11.62,7.41 C11.7586667,7.644 11.9385,7.82383333 12.1595,7.9495 C12.3805,8.07516667 12.647,8.138 12.959,8.138 Z"
                  id="形状"
                ></path>
              </g>
              <polygon
                fill="#FFFFFF"
                id="路径-3"
                points="7.54234314 13.5389099 8.10276794 13.2878113 7.95451355 13.8525391"
              ></polygon>
              <rect fill="#5B7AF6" height="1" id="矩形" width="2" x="10" y="16"></rect>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
