import { SvgIcon, SvgIconProps } from '@mui/material';

export default function IconERC721Token(props: SvgIconProps) {
  return (
    <SvgIcon height="28px" viewBox="0 0 28 28" width="28px" {...props}>
      <g fill="none" fillRule="evenodd" id="页面-1" stroke="none" strokeWidth="1">
        <g id="rollp-bridge-ERC721-2" transform="translate(-734.000000, -304.000000)">
          <g id="编组-5" transform="translate(414.000000, 288.000000)">
            <g id="erc721" transform="translate(320.000000, 16.000000)">
              <circle cx="14" cy="14" fill="#9C64FC" id="椭圆形" r="13.5" stroke="#8C59E3"></circle>
              <g
                fill="#FFFFFF"
                fillRule="nonzero"
                id="ethereum-eth-logo-full-horizontal备份-2"
                transform="translate(6.000000, 1.000000)"
              >
                <path
                  d="M15.9593496,14.7583149 L7.97487066,26 L0,14.7583149 L7.97487066,19.466371 L15.9593496,14.7583149 Z M2.115,16.7033149 L7.679,24.545 L7.679,19.989 L2.115,16.7033149 Z M13.843,16.7013149 L8.279,19.983 L8.279,24.534 L13.843,16.7013149 Z M7.97487066,0 L8.276,0.501 L8.3,0.501084715 L8.3,0.541 L15.9593496,13.2402069 L7.97487066,17.9578714 L0,13.2402069 L7.97487066,0 Z M7.7,10.401 L1.297,13.3098862 L7.7,17.096 L7.7,10.401 Z M8.3,10.423 L8.3,17.067 L14.661,13.3098862 L8.3,10.423 Z M7.7,1.617 L0.963,12.803 L7.7,9.742 L7.7,1.617 Z M8.3,1.699 L8.3,9.765 L14.994,12.802 L8.3,1.699 Z"
                  id="形状结合"
                ></path>
              </g>
              <g id="721" transform="translate(3.915000, 9.270000)">
                <polygon
                  fill="#9C64FC"
                  fillRule="nonzero"
                  id="路径"
                  points="1.443 9.23 4.485 1.742 1.651 1.742 1.651 3.393 0 3.393 0 0.156 6.422 0.156 6.422 1.846 3.497 9.23"
                  stroke="#9C64FC"
                  strokeWidth="1.44"
                ></polygon>
                <path
                  d="M13.648,9.23 L7.226,9.23 L7.226,7.449 L10.021,5.057 C10.2723333,4.84033333 10.4846667,4.64316667 10.658,4.4655 C10.8313333,4.28783333 10.9721667,4.1145 11.0805,3.9455 C11.1888333,3.7765 11.2668333,3.60966667 11.3145,3.445 C11.3621667,3.28033333 11.386,3.10266667 11.386,2.912 L11.386,2.73 C11.386,2.55666667 11.3513333,2.405 11.282,2.275 C11.2126667,2.145 11.1216667,2.03666667 11.009,1.95 C10.8963333,1.86333333 10.7685,1.79833333 10.6255,1.755 C10.4825,1.71166667 10.3373333,1.69 10.19,1.69 C9.97333333,1.69 9.78483333,1.72466667 9.6245,1.794 C9.46416667,1.86333333 9.3255,1.9565 9.2085,2.0735 C9.0915,2.1905 8.994,2.32483333 8.916,2.4765 C8.838,2.62816667 8.77733333,2.79066667 8.734,2.964 L7.031,2.314 C7.135,2.002 7.278,1.70516667 7.46,1.4235 C7.642,1.14183333 7.8695,0.894833333 8.1425,0.6825 C8.4155,0.470166667 8.73833333,0.303333333 9.111,0.182 C9.48366667,0.0606666667 9.904,0 10.372,0 C10.866,0 11.3036667,0.0693333333 11.685,0.208 C12.0663333,0.346666667 12.3891667,0.537333333 12.6535,0.78 C12.9178333,1.02266667 13.1193333,1.31083333 13.258,1.6445 C13.3966667,1.97816667 13.466,2.34 13.466,2.73 C13.466,3.12 13.401,3.47533333 13.271,3.796 C13.141,4.11666667 12.9655,4.41783333 12.7445,4.6995 C12.5235,4.98116667 12.2656667,5.24766667 11.971,5.499 C11.6763333,5.75033333 11.3686667,6.006 11.048,6.266 L9.358,7.592 L13.648,7.592 L13.648,9.23 Z"
                  fill="#9C64FC"
                  fillRule="nonzero"
                  id="路径"
                  stroke="#9C64FC"
                  strokeWidth="1.44"
                ></path>
                <polygon
                  fill="#9C64FC"
                  fillRule="nonzero"
                  id="路径"
                  points="14.907 9.23 14.907 7.657 17.351 7.657 17.351 1.573 17.234 1.573 15.388 3.991 14.14 3.016 16.311 0.156 19.301 0.156 19.301 7.657 21.225 7.657 21.225 9.23"
                  stroke="#9C64FC"
                  strokeWidth="1.44"
                ></polygon>
                <rect fill="#9C64FC" height="3" id="矩形" width="2" x="2" y="3"></rect>
                <rect fill="#9C64FC" height="1" id="矩形" width="2" x="6" y="3"></rect>
                <rect fill="#9C64FC" height="2" id="矩形" width="1" x="6.485" y="6"></rect>
                <polygon
                  fill="#FFFFFF"
                  id="路径-2"
                  points="6.50518127 9.09962891 7.95230957 9.94969604 6.76992126 9.95225952 6.50518127 9.79459045"
                ></polygon>
              </g>
              <rect fill="#9C64FC" height="1.1" id="矩形" width="1" x="20" y="12"></rect>
              <g
                fill="#FFFFFF"
                fillRule="nonzero"
                id="721备份"
                transform="translate(4.019000, 9.270000)"
              >
                <polygon
                  id="路径"
                  points="1.404 9.23 4.693 1.365 1.261 1.365 1.261 3.042 0 3.042 0 0.156 6.149 0.156 6.149 1.43 2.951 9.23"
                ></polygon>
                <path
                  d="M13.414,9.23 L7.252,9.23 L7.252,7.826 L10.151,5.239 C10.6103333,4.823 10.9635,4.4265 11.2105,4.0495 C11.4575,3.6725 11.581,3.276 11.581,2.86 L11.581,2.691 C11.581,2.249 11.4488333,1.90233333 11.1845,1.651 C10.9201667,1.39966667 10.5626667,1.274 10.112,1.274 C9.86066667,1.274 9.64183333,1.31083333 9.4555,1.3845 C9.26916667,1.45816667 9.10666667,1.56 8.968,1.69 C8.82933333,1.82 8.7145,1.97166667 8.6235,2.145 C8.5325,2.31833333 8.461,2.50466667 8.409,2.704 L7.122,2.21 C7.21733333,1.924 7.34733333,1.64883333 7.512,1.3845 C7.67666667,1.12016667 7.88466667,0.884 8.136,0.676 C8.38733333,0.468 8.68416667,0.303333333 9.0265,0.182 C9.36883333,0.0606666667 9.76966667,0 10.229,0 C10.6883333,0 11.1,0.0671666667 11.464,0.2015 C11.828,0.335833333 12.1335,0.522166667 12.3805,0.7605 C12.6275,0.998833333 12.816,1.28266667 12.946,1.612 C13.076,1.94133333 13.141,2.301 13.141,2.691 C13.141,3.055 13.0846667,3.393 12.972,3.705 C12.8593333,4.017 12.7033333,4.31166667 12.504,4.589 C12.3046667,4.86633333 12.0728333,5.13066667 11.8085,5.382 C11.5441667,5.63333333 11.2646667,5.889 10.97,6.149 L8.838,7.982 L13.414,7.982 L13.414,9.23 Z"
                  id="路径"
                ></path>
                <polygon
                  id="路径"
                  points="14.725 9.23 14.725 8.008 17.273 8.008 17.273 1.222 17.169 1.222 15.063 3.471 14.166 2.665 16.48 0.156 18.716 0.156 18.716 8.008 20.926 8.008 20.926 9.23"
                ></polygon>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
