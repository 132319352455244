import { CrossChainMessenger, DEFAULT_L2_CONTRACT_ADDRESSES } from '@eth-optimism/sdk';
import { TransactionReceipt } from '@ethersproject/abstract-provider';
import LoadingButton from '@mui/lab/LoadingButton';
import { BigNumber } from 'ethers';
import React from 'react';
import { useMutation } from 'react-query';
import { useAccount, useSigner } from 'wagmi';

import { BridgeToken, useConfig } from '../../contexts/ConfigContext';
import { addWithdraw } from '../../core/storage/withdraw';
import { Side } from '../../core/type';
import { ZERO_ADDRESS } from '../../core/utils';
import useAlertTransaction from '../../hooks/useAlertTransaction';
import { useReservedParamsNavigate } from '../../hooks/useReservedParamsNavigate';
import { useStaticProvider } from '../../hooks/useStaticProvider';

const WithdrawERC20Button: React.FC<{
  valid?: boolean;
  amount: BigNumber;
  token: BridgeToken;
}> = ({ amount, token, valid }) => {
  const signer = useSigner();
  const { address } = useAccount();
  const { l1, l2 } = useConfig();
  const l1Provider = useStaticProvider('l1');
  const navigate = useReservedParamsNavigate();
  const { alertConfirmed, alertFailed } = useAlertTransaction(Side.l2);

  const { isLoading, mutate: withdrawERC20 } = useMutation<
    | {
        receipt: TransactionReceipt;
        address: string;
        amount: BigNumber;
        tokenAddress: string;
        l1BlockNumber: number;
      }
    | undefined,
    any,
    void
  >({
    mutationFn: async () => {
      if (!signer.data || !signer.isSuccess || !address) return;

      const {
        AddressManager,
        L1CrossDomainMessengerProxy,
        L1StandardBridgeProxy,
        L2OutputOracleProxy,
        OptimismPortalProxy,
      } = l1;
      const messenger = new CrossChainMessenger({
        l1ChainId: l1.id,
        l2ChainId: l2.id,
        l1SignerOrProvider: l1Provider,
        l2SignerOrProvider: signer.data,
        contracts: {
          l1: {
            AddressManager,
            L1CrossDomainMessenger: L1CrossDomainMessengerProxy,
            L1StandardBridge: L1StandardBridgeProxy,
            L2OutputOracle: L2OutputOracleProxy,
            OptimismPortal: OptimismPortalProxy,
            StateCommitmentChain: ZERO_ADDRESS,
            CanonicalTransactionChain: ZERO_ADDRESS,
            BondManager: ZERO_ADDRESS,
          },
          l2: DEFAULT_L2_CONTRACT_ADDRESSES,
        },
      });
      const l1BlockNumber = await l1Provider.getBlockNumber();
      const txResponse = await messenger.withdrawERC20(
        token.oppositeToken.address,
        token.address,
        amount
      );

      const receipt = await txResponse.wait();

      return { address, receipt, tokenAddress: token.address, amount, l1BlockNumber };
    },
    onSuccess(data) {
      if (!data) {
        return;
      }

      const { address, amount, l1BlockNumber, receipt, tokenAddress } = data;
      const hash = receipt.transactionHash;

      alertConfirmed(hash);

      try {
        addWithdraw({
          amountOrTokenId: amount,
          tokenAddress,
          account: address,
          transactionHash: hash,
          l1BlockNumber,
        });
        navigate(`/withdraw/progress/${hash}`, { id: true });
      } catch (e) {
        console.error('parse error', e);
      }
    },
    onError(e) {
      console.error(e);
      alertFailed(e.message);
    },
  });

  return (
    <LoadingButton
      disabled={!valid}
      fullWidth
      loading={isLoading}
      onClick={() => withdrawERC20()}
      variant="contained"
    >
      Withdraw
    </LoadingButton>
  );
};

export default WithdrawERC20Button;
