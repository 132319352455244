import { BigNumberish } from 'ethers';

import { getStorageKey } from './deposit';

export const WITHDRAW_KEY = 'withdraw-list';

export interface Withdraw {
  transactionHash: string;
  from: string;
  amountOrTokenId: string;
  // for indexing token
  tokenAddress: string;
  timestamp: string;
  l1BlockNumber: number;
}

interface IAddWithdraw {
  amountOrTokenId: BigNumberish;
  tokenAddress: string;
  account: string;
  transactionHash: string;
  l1BlockNumber: number;
}

export function saveWithdrawToStorage(withdraw: Withdraw) {
  const data = localStorage.getItem(getStorageKey('withdrawal'));
  let withdrawList: Withdraw[] = [];

  try {
    withdrawList = data ? JSON.parse(data) : [];
  } catch (e) {
    console.debug(e);
  }

  const existingIndex = withdrawList.findIndex(
    _withdraw =>
      _withdraw.transactionHash &&
      withdraw.transactionHash &&
      _withdraw.transactionHash === withdraw.transactionHash
  );

  if (existingIndex < 0) {
    withdrawList.push(withdraw);
  } else {
    withdrawList.splice(existingIndex, 1, withdraw);
  }

  localStorage.setItem(getStorageKey('withdrawal'), JSON.stringify(withdrawList));
}

export function addWithdraw({
  account,
  amountOrTokenId,
  l1BlockNumber,
  tokenAddress,
  transactionHash,
}: IAddWithdraw) {
  const withdraw: Withdraw = {
    transactionHash,
    from: account,
    l1BlockNumber,
    amountOrTokenId: amountOrTokenId.toString(),
    tokenAddress,
    timestamp: new Date().toISOString(),
  };

  saveWithdrawToStorage(withdraw);
}

export function getWithdrawByTransactionHash(transactionHash: string) {
  const data = localStorage.getItem(getStorageKey('withdrawal'));
  let withdrawList: Withdraw[] = [];

  try {
    withdrawList = data ? JSON.parse(data) : [];
  } catch (e) {
    console.log(e);
  }

  const withdrawal = withdrawList.find(withdraw => withdraw.transactionHash === transactionHash);

  return withdrawal;
}

export function getAllWithdrawal(): Withdraw[] {
  try {
    const widthdrawls = JSON.parse(localStorage.getItem(getStorageKey('withdrawal')) || '[]');
    let found = false;

    // hack for backward-compatibale
    widthdrawls.forEach((withdrawal: any) => {
      if (withdrawal.hash) {
        withdrawal.withdrawalHash = withdrawal.hash;
        found = true;
      }
    });

    if (found) {
      localStorage.setItem(getStorageKey('withdrawal'), JSON.stringify(widthdrawls));
    }

    return widthdrawls as Withdraw[];
  } catch (e) {
    localStorage.setItem(getStorageKey('withdrawal'), JSON.stringify([]));

    return [];
  }
}
