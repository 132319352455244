import {
  CrossChainMessenger,
  DEFAULT_L2_CONTRACT_ADDRESSES,
  MessageStatus,
} from '@eth-optimism/sdk';
import { MutableRefObject, useMemo, useRef, useState } from 'react';

import { sleep, ZERO_ADDRESS } from '../core/utils';
import { useConfig } from './../contexts/ConfigContext';
import { useExecuteOnce } from './useExecuteOnce';
import { useStaticProvider } from './useStaticProvider';
import { useWithdrawInLS } from './useWithdrawInLS';

export enum WithdrawalStatus {
  UNKNOWN,
  STATE_ROOT_NOT_PUBLISHED,
  READY_TO_PROVE,
  IN_CHALLENGE_PERIOD,
  READY_FOR_RELAY,
  RELAYED,
}

export const useWithdrawalStatus = (transactionHash?: string) => {
  const { l1, l2 } = useConfig();
  const l1Provider = useStaticProvider('l1');
  const l2Provider = useStaticProvider('l2');
  const withdrawal = useWithdrawInLS(transactionHash);
  const [status, setStatus] = useState(WithdrawalStatus.UNKNOWN);
  const statusRef = useRef(status);
  const messenger = useMemo(() => {
    const {
      AddressManager,
      L1CrossDomainMessengerProxy,
      L1StandardBridgeProxy,
      L2OutputOracleProxy,
      OptimismPortalProxy,
    } = l1;

    return new CrossChainMessenger({
      l1ChainId: l1.id,
      l2ChainId: l2.id,
      l1SignerOrProvider: l1Provider,
      l2SignerOrProvider: l2Provider,
      contracts: {
        l1: {
          AddressManager,
          L1CrossDomainMessenger: L1CrossDomainMessengerProxy,
          L1StandardBridge: L1StandardBridgeProxy,
          L2OutputOracle: L2OutputOracleProxy,
          OptimismPortal: OptimismPortalProxy,
          StateCommitmentChain: ZERO_ADDRESS,
          CanonicalTransactionChain: ZERO_ADDRESS,
          BondManager: ZERO_ADDRESS,
        },
        l2: DEFAULT_L2_CONTRACT_ADDRESSES,
      },
    });
  }, [l1, l1Provider, l2.id, l2Provider]);

  const argsRef = useExecuteOnce<{
    l1BlockNumber: number | undefined;
    messenger: CrossChainMessenger;
    transactionHash: string | undefined;
  }>(async (destroiedRef: MutableRefObject<boolean>, argsRef) => {
    while (!destroiedRef.current && statusRef.current !== WithdrawalStatus.RELAYED) {
      if (!argsRef.current || !argsRef.current.l1BlockNumber || !argsRef.current.transactionHash) {
        await sleep(1000);
        continue;
      }

      try {
        const status = await argsRef.current.messenger.getMessageStatus(
          argsRef.current.transactionHash,
          undefined,
          argsRef.current.l1BlockNumber
        );
        let withdrawStatus = WithdrawalStatus.UNKNOWN;

        if (status === MessageStatus.STATE_ROOT_NOT_PUBLISHED) {
          withdrawStatus = WithdrawalStatus.STATE_ROOT_NOT_PUBLISHED;
        } else if (status === MessageStatus.READY_TO_PROVE) {
          withdrawStatus = WithdrawalStatus.READY_TO_PROVE;
        } else if (status === MessageStatus.IN_CHALLENGE_PERIOD) {
          withdrawStatus = WithdrawalStatus.IN_CHALLENGE_PERIOD;
        } else if (status === MessageStatus.READY_FOR_RELAY) {
          withdrawStatus = WithdrawalStatus.READY_FOR_RELAY;
        } else if (status === MessageStatus.RELAYED) {
          withdrawStatus = WithdrawalStatus.RELAYED;
        } else {
          withdrawStatus = WithdrawalStatus.UNKNOWN;
        }

        statusRef.current = withdrawStatus;
        setStatus(withdrawStatus);
      } catch (e) {
        // @Todo: In case of ABI version of SDK is inconsistency with OP Node's contract.
        // Simply set the status `STATE_ROOT_NOT_PUBLISHED`
        statusRef.current = WithdrawalStatus.STATE_ROOT_NOT_PUBLISHED;
        setStatus(WithdrawalStatus.STATE_ROOT_NOT_PUBLISHED);
      }

      await sleep(window.appConfig.rpcPollingInterval || 60000);
    }
  });

  argsRef.current = {
    l1BlockNumber: withdrawal?.l1BlockNumber,
    messenger,
    transactionHash,
  };

  return status;
};
