import { SvgIcon, SvgIconProps } from '@mui/material';

export default function IconMetamask(props: SvgIconProps) {
  return (
    <SvgIcon height="30px" viewBox="0 0 30 30" width="30px" {...props}>
      <g fill="none" fillRule="evenodd" id="页面-1" stroke="none" strokeWidth="1">
        <g id="bridge" transform="translate(-1018.000000, -37.000000)">
          <g id="编组-10" transform="translate(922.000000, 30.000000)">
            <g id="编组-9" transform="translate(96.000000, 7.000000)">
              <rect fill="#FFFFFF" height="30" id="矩形" rx="6" width="30" x="0" y="0"></rect>
              <g
                fillRule="nonzero"
                id="Metamask"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0.25"
                transform="translate(4.000000, 5.000000)"
              >
                <polygon
                  fill="#E17726"
                  id="路径"
                  points="20.9119452 0 12.3176339 6.35917921 13.9158207 2.61150825"
                  stroke="#E17726"
                ></polygon>
                <polygon
                  fill="#E27625"
                  id="路径"
                  points="1.088081 0 9.6058725 6.41852884 8.08424472 2.61150171"
                  stroke="#E27625"
                ></polygon>
                <polygon
                  fill="#E27625"
                  id="路径"
                  points="17.8174539 14.7447526 15.530759 18.2380707 20.427202 19.5862301 21.8298687 14.8211153"
                  stroke="#E27625"
                ></polygon>
                <polygon
                  fill="#E27625"
                  id="路径"
                  points="0.178448058 14.8211153 1.57256901 19.5862301 6.46053817 18.2380707 4.18231051 14.7447526"
                  stroke="#E27625"
                ></polygon>
                <polygon
                  fill="#E27625"
                  id="路径"
                  points="6.1969656 8.84348817 4.83686435 10.895402 9.6823007 11.1158528 9.52080687 5.90132982"
                  stroke="#E27625"
                ></polygon>
                <polygon
                  fill="#E27625"
                  id="路径"
                  points="15.8027727 8.8435536 12.4280229 5.84203253 12.317503 11.1159183 17.1629066 10.8954674"
                  stroke="#E27625"
                ></polygon>
                <polygon
                  fill="#E27625"
                  id="路径"
                  points="6.46060361 18.2380707 9.3933393 16.8221206 6.86865689 14.8550107"
                  stroke="#E27625"
                ></polygon>
                <polygon
                  fill="#E27625"
                  id="路径"
                  points="12.6066607 16.8221206 15.5308898 18.2380707 15.1313431 14.8550107"
                  stroke="#E27625"
                ></polygon>
                <polygon
                  fill="#D5BFB2"
                  id="路径"
                  points="15.5308898 18.2382016 12.6066607 16.8222515 12.8446482 18.7215052 12.8191285 19.5270113"
                  stroke="#D5BFB2"
                ></polygon>
                <polygon
                  fill="#D5BFB2"
                  id="路径"
                  points="6.46060361 18.2382016 9.18087154 19.5270113 9.16385841 18.7215052 9.3933393 16.8222515"
                  stroke="#D5BFB2"
                ></polygon>
                <polygon
                  fill="#233447"
                  id="路径"
                  points="9.23178004 13.6001618 6.80053895 12.8879662 8.51768681 12.0994078"
                  stroke="#233447"
                ></polygon>
                <polygon
                  fill="#233447"
                  id="路径"
                  points="12.7681545 13.6001618 13.4822478 12.0994078 15.2079022 12.8879662"
                  stroke="#233447"
                ></polygon>
                <polygon
                  fill="#CC6228"
                  id="路径"
                  points="6.46053817 18.2380707 6.88560458 14.7447526 4.18234977 14.8211153"
                  stroke="#CC6228"
                ></polygon>
                <polygon
                  fill="#CC6228"
                  id="路径"
                  points="15.1142646 14.7447526 15.5308244 18.2380707 17.8175194 14.8211153"
                  stroke="#CC6228"
                ></polygon>
                <polygon
                  fill="#CC6228"
                  id="路径"
                  points="17.1629066 10.8953366 12.317503 11.1157874 12.7680237 13.6001618 13.4821169 12.0993424 15.2077713 12.8879008"
                  stroke="#CC6228"
                ></polygon>
                <polygon
                  fill="#CC6228"
                  id="路径"
                  points="6.80053895 12.8879008 8.51768681 12.0993424 9.23178004 13.6001618 9.6823007 11.1157874 4.83686435 10.8953366"
                  stroke="#CC6228"
                ></polygon>
                <polygon
                  fill="#E27525"
                  id="路径"
                  points="4.83702139 10.8953366 6.86872232 14.8550107 6.80066982 12.8879008"
                  stroke="#E27525"
                ></polygon>
                <polygon
                  fill="#E27525"
                  id="路径"
                  points="15.2079022 12.8879008 15.1314085 14.8550107 17.1631029 10.8953366"
                  stroke="#E27525"
                ></polygon>
                <polygon
                  fill="#E27525"
                  id="路径"
                  points="9.68256244 11.1157874 9.23197635 13.6001618 9.80152345 16.5338136 9.92905646 12.6674499"
                  stroke="#E27525"
                ></polygon>
                <polygon
                  fill="#E27525"
                  id="路径"
                  points="12.3176339 11.1157874 12.079581 12.6590088 12.1986074 16.5338136 12.7681545 13.6001618"
                  stroke="#E27525"
                ></polygon>
                <polygon
                  fill="#F5841F"
                  id="路径"
                  points="12.7681545 13.6002272 12.1986074 16.533879 12.6066607 16.8221861 15.1314085 14.8550761 15.2079022 12.8879662"
                  stroke="#F5841F"
                ></polygon>
                <polygon
                  fill="#F5841F"
                  id="路径"
                  points="6.80053895 12.8879662 6.86859145 14.8550761 9.39327387 16.8221861 9.80132714 16.533879 9.23178004 13.6002272"
                  stroke="#F5841F"
                ></polygon>
                <polygon
                  fill="#C0AC9D"
                  id="路径"
                  points="12.8191939 19.5269459 12.8446482 18.7214398 12.6236738 18.5348843 9.37639161 18.5348843 9.16385841 18.7214398 9.18087154 19.5269459 6.46060361 18.2381362 7.4126843 19.018188 9.34236536 20.3493996 12.6491281 20.3493996 14.5873157 19.018188 15.5308898 18.2381362"
                  stroke="#C0AC9D"
                ></polygon>
                <polygon
                  fill="#161616"
                  id="路径"
                  points="12.6065953 16.8221206 12.198542 16.533879 9.80132714 16.533879 9.3933393 16.8221206 9.16379298 18.7214398 9.37632618 18.5348843 12.6236084 18.5348843 12.8446482 18.7214398"
                  stroke="#161616"
                ></polygon>
                <polygon
                  fill="#763E1A"
                  id="路径"
                  points="21.2774002 6.77462665 22 3.2643804 20.9118797 0 12.6066607 6.14723492 15.8029035 8.84348817 20.316813 10.1577521 21.3114265 8.99614825 20.8779189 8.68238695 21.566427 8.05499523 21.0394127 7.64798891 21.7279863 7.12228333"
                  stroke="#763E1A"
                ></polygon>
                <polygon
                  fill="#763E1A"
                  id="路径"
                  points="0 3.2643804 0.731067098 6.77462665 0.263526773 7.12228333 0.96058725 7.64798891 0.433540247 8.05499523 1.1221007 8.68238695 0.688560458 8.99614825 1.68315433 10.1577521 6.19709647 8.84348817 9.3933393 6.14723492 1.08810063 0"
                  stroke="#763E1A"
                ></polygon>
                <polygon
                  fill="#F5841F"
                  id="路径"
                  points="20.3168784 10.1576867 15.802969 8.84348817 17.1631029 10.895402 15.1314085 14.8550107 17.8176502 14.8211153 21.8299996 14.8211153"
                  stroke="#F5841F"
                ></polygon>
                <polygon
                  fill="#F5841F"
                  id="路径"
                  points="6.1969656 8.84348817 1.68308235 10.1576867 0.178448058 14.8211153 4.18231051 14.8211153 6.86852601 14.8550107 4.83687089 10.895402"
                  stroke="#F5841F"
                ></polygon>
                <polygon
                  fill="#F5841F"
                  id="路径"
                  points="12.3175684 11.1158528 12.6065953 6.14716949 13.9156898 2.61149516 8.08417928 2.61149516 9.39327387 6.14716949 9.6823007 11.1158528 9.79282058 12.6759565 9.80132714 16.533879 12.198542 16.533879 12.2070485 12.6759565"
                  stroke="#F5841F"
                ></polygon>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
